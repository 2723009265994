import * as React from "react";
import dayjs from "dayjs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ModalVideo from 'react-modal-video'
import '../../node_modules/react-modal-video/css/modal-video.css';
import InfiniteScroll from "react-infinite-scroll-component";
// markup
const PredicasPage = () => {

  const YOUTUBE_CHANNEL_ITEMS_API = 'https://www.googleapis.com/youtube/v3/search';

  const YOUTUBE_API_KEY = "AIzaSyCll4c3jeBO1_AABCutNsQoPsUwol3yVeM";

  const CHANNEL_ID = 'UCjQlPKVtbffXG3sk2u4uKBQ';

  const YOUTUBE_URL = `${YOUTUBE_CHANNEL_ITEMS_API}?key=${YOUTUBE_API_KEY}&part=snippet&channelId=${CHANNEL_ID}&channelType=any&maxResults=10&order=date&pageToken=`

  const [predicas, setPredicas] = React.useState([])
  const [nextPage, setNextPage] = React.useState('')
  const [currentVideo, setCurrentVideo] = React.useState('nJGpUo1HZjI');
  const [videoOpened, setVideoOpened] = React.useState(false);

  const getVideosData = async () => {
    const res = await fetch(YOUTUBE_URL + (nextPage ? nextPage : ''));
    const data = await res.json();
    setNextPage(data.nextPageToken);
    setPredicas(predicas => [...predicas, ...data.items])
  }

  React.useEffect(() => {
    getVideosData();
  }, [])

  function resize(event) {
    const frames = document.getElementsByTagName("iframe");
    Array.from(frames).forEach((el) => {
      const ratio = 1.777777777777778;
      const parentDivWidth = el.offsetWidth;
      const newHeight = parentDivWidth / ratio;
      el.height = newHeight;
    });
  }

  React.useEffect(() => {
    resize();
  }, []);

  if (typeof window !== 'undefined') {
    window.addEventListener("resize", resize);
  }


  return (
    <>
      <Header></Header>
      <ModalVideo channel='youtube' autoplay isOpen={videoOpened} videoId={currentVideo} onClose={() => setVideoOpened(false)} />

      <InfiniteScroll
        dataLength={predicas.length}
        next={getVideosData}
        hasMore={Boolean(nextPage)}
        loader={<h4>Loading...</h4>}
      >
        <div className="Index-page-content predicas">
          <div className="sqs-layout sqs-grid-12 columns-12">
            <div className="row sqs-row" id="yui_3_17_2_1_1633227834863_306">
              <div
                className="col sqs-col-12 span-12"
                id="yui_3_17_2_1_1633227834863_305"
              >
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-56b231af4d088e92779c5d21"
                >
                  <div className="sqs-block-content">
                    <h1 style={{ textAlign: "center" }}>Prédicas</h1>
                  </div>
                </div>

                <div
                  className="sqs-block spacer-block sqs-block-spacer"
                  data-aspect-ratio="2.2"
                  data-block-type="21"
                  id="block-yui_3_17_2_19_1454526015148_21520"
                >
                  <div
                    className="sqs-block-content sqs-intrinsic"
                    id="yui_3_17_2_1_1633227834863_134"
                  >
                    &nbsp;
                  </div>
                </div>


                <div className="row sqs-row">
                  <div className="sqs-block html-block sqs-block-html">
                    <div className="row sqs-row">
                      {predicas.map((p) => {
                        return (
                          <div className="col sqs-col-6 span-6">
                            <div className="sqs-block">
                              <div className="video">
                                <a
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setVideoOpened(true)
                                    setCurrentVideo(p?.id?.videoId);
                                    console.log(['p.id.videoId', p?.id?.videoId])

                                  }}
                                >
                                  <img
                                    style={{ width: '100%' }}
                                    className="thumb-image loaded"
                                    data-component-key="idv1.i.56f049d6b09f958b0995b18e"
                                    data-src={p.snippet?.thumbnails?.high?.url}
                                    data-image={p.snippet?.thumbnails?.high?.url}
                                    data-image-dimensions="1000x1230"
                                    data-image-focal-point="0.5,0.5"
                                    alt=""
                                    data-load="false"
                                    data-image-id="56f049d6b09f958b0995b18e"
                                    data-type="image"
                                    data-image-resolution="1500w"
                                    src={p?.snippet?.thumbnails?.high?.url}
                                  />
                                </a>

                                {/* <iframe
                                src={`https://www.youtube.com/embed/${p.videoId}`}
                                title={p.title}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                                width={"100%"}
                                height={232}
                              /> */}
                                <p className="predica-date ">
                                  {dayjs(p?.snippet?.publishedAt, "MM-DD-YYYY").format(
                                    "MMMM DD, YYYY"
                                  )}
                                </p>
                                <p className="predica-titulo ">{p?.snippet?.title}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="sqs-block spacer-block sqs-block-spacer">
                  <div className="sqs-block-content sqs-intrinsic">&nbsp;</div>
                </div>

                {/* <div></div> */}
              </div>
            </div>
          </div>
        </div>
      </InfiniteScroll>

      <Footer></Footer>
    </>
  );
};

export default PredicasPage;
